import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isSidebarOpen: false,
    latLngInfo: {
      G07: {
        lat: 0,
        lng: 0,
        key: 'g07',
      },
      G08: {
        lat: 0,
        lng: 0,
        key: 'g08',
      },
      G09: {
        lat: 0,
        lng: 0,
        key: 'g09',
      },
      G10: {
        lat: 0,
        lng: 0,
        key: 'g10',
      },
      G11: {
        lat: 0,
        lng: 0,
        key: 'g11',
      },
      G12: {
        lat: 0,
        lng: 0,
        key: 'g12',
      },
      北出土: {
        lat: 0,
        lng: 0,
        key: 'nt',
      },
    },
    machineInfo: [],
    wsCctv: [],
    wsPeople: {},
    accessToken: null,
    ytUrl: '',
    isLogin: false,
    dashboardInfo: {},
    stationInfoList: [
      {
        key: 'g07',
        name: 'G07/桃園車站',
        sideName: 'G07',
        device: ['✦人臉辨識', '✦CCTV', '✦氣體偵測', '✦電子佈告欄'],
      },
      {
        key: 'g08',
        name: 'G08',
        sideName: 'G08',
        device: ['✦CCTV'],
      },
      {
        key: 'g09',
        name: 'G09',
        sideName: 'G09',
        device: ['✦CCTV'],
      },
      {
        key: 'g10',
        name: 'G10',
        sideName: 'G10',
        device: ['✦人臉辨識', '✦CCTV'],
      },
      {
        key: 'g11',
        name: 'G11',
        sideName: 'G11',
        device: ['✦CCTV'],
      },
      {
        key: 'g12',
        name: 'G12',
        sideName: 'G12',
        device: ['✦人臉辨識', '✦CCTV'],
      },
      {
        key: 'nt',
        name: '北出土',
        sideName: '北出土',
        device: ['✦人臉辨識', '✦CCTV'],
      },
    ],
  },
  mutations: {
    setSidebarOpen(state, param) {
      console.log('setSidebarOpen', param)
      Vue.set(state, 'isSidebarOpen', param)
    },
    setLatLngInfo(state, param) {
      if (state.latLngInfo[param.side] !== undefined) {
        state.latLngInfo[param.side].lat = param.lat
        state.latLngInfo[param.side].lng = param.lng
      }
    },
    setMachineInfo(state, param) {
      console.log('setMachineInfo', param)
      Vue.set(state, 'machineInfo', param)
    },
    setDashboardInfo(state, param) {
      console.log('setDashboardInfo', param)
      Vue.set(state, 'dashboardInfo', param)
    },
    setWsCctv(state, param) {
      console.log('wsCctv', JSON.parse(param.data))
      state.wsCctv.push(JSON.parse(param.data))
    },
    setIsLogin(state, param) {
      Vue.set(state, 'isLogin', param)
    },
    setYtUrl(state, param) {
      Vue.set(state, 'ytUrl', param)
    },
    clearWsCctv(state) {
      Vue.set(state, 'wsCctv', [])
    },
    setAccessToken(state, param) {
      state.accessToken = param
    },
    setWsPeople(state, param) {
      Vue.set(state, 'wsPeople', param)
    },
  },
  actions: {},
  modules: {},
  plugins: [createPersistedState()],
})
