<template>
  <div id="app">
    <div id="nav" v-if="showNav">
      <button
        class="btn-burger"
        :class="{ open: isSidebarOpen }"
        @click="toggleSidebar()"
      ></button>
      <span>{{ nowDate }}</span>
      <span>{{ timestamp }}</span>
      <span>桃園市</span>
      <span>{{ dashboardInfo.weather }}</span>
      <span>現在溫度：{{ dashboardInfo.temperature }}°C</span>
      <span>相對濕度：{{ dashboardInfo.humidity }}%</span>
      <span>風速：{{ dashboardInfo.windSpeed }}m/s </span>
      <button class="btn-log-out" @click="logOut()"></button>
    </div>
    <div id="sidebar" :class="{ show: isSidebarOpen }" v-if="isLogin">
      <div>
        <p
          class="sidebar-link"
          :class="{ active: isActive(stationInfo.key) }"
          v-for="stationInfo in stationInfoList"
          :key="stationInfo.key"
          @click="goStation(stationInfo.key)"
        >
          {{ stationInfo.sideName }}
        </p>
      </div>
      <div>
        <p class="sidebar-link" @click="logOut()" v-if="isLogin">登出</p>
      </div>
    </div>
    <router-view :key="$route.fullPath" />
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import { getDashboardData, getMachineData } from '@/api/index.js'
export default {
  data() {
    return {
      dashboardInfo: {},
      timestamp: '',
      nowDate:
        new Date().getFullYear() +
        '.' +
        (new Date().getMonth() + 1) +
        '.' +
        new Date().getDate(),
      lastPage: {},
      windowWidth: window.innerWidth,
    }
  },
  computed: {
    ...mapState([
      'isLogin',
      'accessToken',
      'stationInfoList',
      'latLngInfo',
      'isSidebarOpen',
    ]),
    showNav() {
      return (
        this.$route.name !== 'Login' || (this.windowWidth < 768 && this.isLogin)
      )
    },
  },
  watch: {
    accessToken(newVal) {
      if (newVal) {
        getDashboardData()
          .then((res) => {
            this.dashboardInfo = res.data
            this.setDashboardInfo(this.dashboardInfo)
            this.setYtUrl(this.dashboardInfo.youtubePlaylistUrl)
          })
          .catch((error) => {
            console.log(error)
          })
      }
    },
    $route(to, from) {
      this.lastPage = from
    },
  },
  created() {
    getDashboardData()
      .then((res) => {
        this.dashboardInfo = res.data
        this.setDashboardInfo(this.dashboardInfo)
        this.setYtUrl(this.dashboardInfo.youtubePlaylistUrl)
      })
      .catch((error) => {
        console.log(error)
      })
    getMachineData()
      .then((res) => {
        this.setMachineInfo(res.data.rows)
        let groupIcon = Object.groupBy(
          res.data.rows,
          ({ machine_group }) => machine_group
        )
        Object.keys(groupIcon).forEach((group) => {
          const params = {
            side: group,
            lat: this.getMiddle('latitude', groupIcon[group]),
            lng: this.getMiddle('longitude', groupIcon[group]),
            key: group.toLocaleLowerCase(),
          }
          if (group === 'G07-1') {
            params.side === 'GO7'
          }
          if (group === '北出土') {
            params.key === 'nt'
          }
          this.setLatLngInfo(params)
        })
      })
      .catch((error) => {
        console.log(error)
      })
    setInterval(() => {
      this.getNow()
    }, 1000)
    this.nowDate =
      new Date().getFullYear() +
      '.' +
      (new Date().getMonth() + 1) +
      '.' +
      new Date().getDate()
    console.log(' nowDate', this.nowDate)
    setInterval(() => {
      console.log(' setInterval nowDate', this.nowDate)
      this.nowDate =
        new Date().getFullYear() +
        '.' +
        (new Date().getMonth() + 1) +
        '.' +
        new Date().getDate()
      console.log('nowDate', this.nowDate)
      getDashboardData()
        .then((res) => {
          this.dashboardInfo = res.data
          this.setDashboardInfo(this.dashboardInfo)
        })
        .catch((error) => {
          console.log(error)
        })
    }, 60000)
    setInterval(() => {
      const currentTime = new Date()
      const currentHour = currentTime.getHours()
      const currentMinutes = currentTime.getMinutes()
      if (currentHour === 12 && currentMinutes === 0) {
        console.log('currentHour 12 AM!', currentHour)
        window.location.reload()
        getDashboardData()
          .then((res) => {
            this.dashboardInfo = res.data
            this.setYtUrl(this.dashboardInfo.youtubePlaylistUrl)
          })
          .catch((error) => {
            console.log(error)
          })
      }
    }, 3600000)
  },
  methods: {
    ...mapMutations([
      'setAccessToken',
      'setYtUrl',
      'setIsLogin',
      'setDashboardInfo',
      'setMachineInfo',
      'setLatLngInfo',
      'setSidebarOpen',
    ]),
    isActive(key) {
      return this.$route.params.key === key
    },
    getMiddle(prop, markers) {
      let values = markers.map((m) => m[prop])
      let min = Math.min(...values)
      let max = Math.max(...values)
      if (prop === 'longitude' && max - min > 180) {
        values = values.map((val) => (val < max - 180 ? val + 360 : val))
        min = Math.min(...values)
        max = Math.max(...values)
      }
      let result = (min + max) / 2
      if (prop === 'longitude' && result > 180) {
        result -= 360
      }
      return result
    },
    logOut() {
      this.setAccessToken(null)
      this.setSidebarOpen(false)
      this.setIsLogin(false)
      if (this.$router.name === 'Login') return
      this.$router.push({
        name: 'Login',
      })
    },
    goBack() {
      this.$router.push(this.lastPage)
    },
    getNow() {
      const today = new Date()
      const time =
        (today.getHours() < 10 ? '0' : '') +
        today.getHours() +
        ':' +
        (today.getMinutes() < 10 ? '0' : '') +
        today.getMinutes() +
        ':' +
        (today.getSeconds() < 10 ? '0' : '') +
        today.getSeconds()
      this.timestamp = time
    },
    goStation(key) {
      if (!this.isLogin) return
      this.$router.push({ name: 'Map', params: { key: key } })
      this.setSidebarOpen(false)
    },
    toggleSidebar() {
      if (this.isSidebarOpen) {
        this.setSidebarOpen(false)
      } else {
        this.setSidebarOpen(true)
      }
    },
  },
}
</script>
