import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'jquery/dist/jquery.min'
import 'bootstrap/dist/css/bootstrap.min.css'
import './styles/index.scss'
import { get, post } from '@/api/http.js'
import VueYouTubeEmbed from 'vue-youtube-embed'
import 'leaflet/dist/leaflet.css'

Vue.use(VueYouTubeEmbed)
Vue.config.productionTip = false
Vue.prototype.$post = post
Vue.prototype.$get = get

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
