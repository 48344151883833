import { post, getWithToken } from './http'
export const getCctv = (params) =>
  getWithToken('/ad-machine/camera-urls', params)
export const getMachineData = () => getWithToken('/machine/data')
export const getDashboardData = () => getWithToken('/ad-machine/data')
export const goLogin = (params) => post('/auth/login', params)
export const getPeopleCount = (params) =>
  getWithToken('/people/count?device_group=' + params)
export const getAir01 = (params) => getWithToken('/air01/common/' + params)
export const getAir02 = (params) =>
  getWithToken('/air02/datastore?id=' + params)
export const test = (params) => post('/device/api/v1/record/face', params)
