import Vue from 'vue'
import VueRouter from 'vue-router'
// 获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
// 修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/map/:key',
    name: 'Map',
    component: () => import('../views/Map.vue'),
  },
  {
    path: '/dashboard/:key',
    name: 'Dashboard',
    component: () => import('../views/Dashboard.vue'),
  },
  {
    path: '/cctvList/:key',
    name: 'CctvList',
    component: () => import('../views/CctvList.vue'),
  },
  {
    path: '/cctv/:key/:id',
    name: 'Cctv',
    component: () => import('../views/Cctv.vue'),
  },
  {
    path: '/people/:key',
    name: 'People',
    component: () => import('../views/People.vue'),
  },
  {
    path: '/env/:key/:id',
    name: 'Env',
    component: () => import('../views/Env.vue'),
  },
  {
    path: '/envpm/:key/:id',
    name: 'EnvPM',
    component: () => import('../views/EnvPM.vue'),
  },
]

const router = new VueRouter({
  routes,
})

export default router
